
export const PAGE_ROUTES = {
  HOME: "/",
  SEARCH: "/search/:searchQuery",
  GROUP: "/our-initiatives",
  LEGACY: "/our-legacy",
  PARTNERS: "/our-partners",
  TEAM: "/our-team",
  CONTACT: "/contact-us",
  NEWSLETTER: "/newsletter",
  PRIVACY_POLICY: "/privacy-policy",
  TIMEPIECES: "/timepieces",
  JEWELLERY: "/jewellery",
  CATEGORY: "/category/:categoryUrl",
  PRODUCT: "/product/:productUrl",
  PRESS: "/media-portal",
  ROLEX: "/en/rolex/*",
  NOT_FOUND: "/404",
};

export const getCategoryRoute = (catUrl) => {
  if (catUrl === "rolex") {
    return "/en/rolex/home-rolex";
  }
  return PAGE_ROUTES.CATEGORY.replace(":categoryUrl", catUrl);
};

export const ADMIN_PAGE_ROUTES = {
  HOME: "/admin",
  BANNERS: "/admin/banners",
  BANNER_EDIT: "/admin/banners/:bannerID",
  CATEGORIES: "/admin/categories",
  CATEGORY_EDIT: "/admin/categories/:categoryID",
  PRODUCT_EDIT: "/admin/categories/:categoryID/product/:productID",
  PRESS_EDIT: "/admin/media-portal",
};

export const LOADING_STATE = {
  NOT_LOADING: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};

export const LS_ITEM = {
  LOGIN: "ls_1",
  CATEGORIES_LOAD: "ls_2",
  BANNERS_LOAD: "ls_3",
  CATEGORY_DETAILS_LOAD: "ls_4_",
  PRODUCT_LOAD: "ls_5_",
  CONTACT_SEND: "ls_6",
  SEARCH: "ls_7",
  PRESS_LOAD: "ls_8",
  CATEGORY_PRODUCTS_LOAD: "ls_9_",
};

export const LS_ITEM_ADMIN = {
  BANNERS_LOAD: "ls_1",
  BANNER_ADD: "ls_2",
  BANNER_SAVE: "ls_3",
  BANNER_DELETE: "ls_4_",
  BANNER_REORDER: "ls_5_",
  CATEGORIES_LOAD: "ls_6",
  CATEGORY_ADD: "ls_7",
  CATEGORY_SAVE: "ls_8",
  CATEGORY_DELETE: "ls_9_",
  CATEGORY_REORDER: "ls_10_",
  PRODUCTS_LOAD: "ls_11",
  PRODUCT_ADD: "ls_12",
  PRODUCT_SAVE: "ls_13",
  PRODUCT_DELETE: "ls_14_",
  PRODUCT_REORDER: "ls_15_",
  PRESS_LOAD: "ls_16",
  PRESS_SAVE: "ls_17",
};

export const NOTIF_TYPE = {
  STANDARD: 0,
  ERROR: 1,
  SUCCESS: 2,
};

export const CATEGORY_TYPE = {
  TIMEPIECES: 0,
  JEWELLERY: 1,
};

export const ENQUIRY_TYPE = {
  GENERAL: "General Enquiry",
  PRODUCT: "Product Enquiry",
  REPAIR: "Maintenance/Restoration Enquiry",
  CUSTOM: "Custom Design Enquiry",
};

export const SEARCH_RESULT_TYPE = {
  CATEGORY: 0,
  PRODUCT: 1,
};

export const FORM_PROP_TYPE = {
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  CHECKBOX: "checkbox",
  IMAGE: "image",
  VIDEO: "video",
  COLOR: "color",
  SELECT: "select",
  SUBLIST: "sublist",
  CUSTOM: "custom",
  LINK_OR_FILE: "link_or_file",
  MARKDOWN: "markdown",
  SKIP: "skip",
};