export const RECEIVE_CATEGORIES = "home/RECEIVE_CATEGORIES";
export const RECEIVE_CATEGORY_DETAILS = "home/RECEIVE_CATEGORY_DETAILS";
export const RECEIVE_CATEGORY_PRODUCTS = "home/RECEIVE_CATEGORY_PRODUCTS";
export const RECEIVE_BANNERS = "home/RECEIVE_BANNERS";

export const RECEIVE_PRODUCT = "home/RECEIVE_PRODUCT";

export const UPDATE_CONTACT_FIELD = "home/UPDATE_CONTACT_FIELD";
export const RESET_CONTACT_FIELDS = "home/RESET_CONTACT_FIELDS";

export const REFCEIVE_SEARCH_RESULTS = "home/REFCEIVE_SEARCH_RESULTS";

export const SET_SHOW_CONSENT = "home/SET_SHOW_CONSENT";

export const RECEIVE_PRESS = "home/RECEIVE_PRESS";