import * as actionType from "./actionTypes";
import { createReducer, updateObject } from "../../utils/reducerUtils";
import { CATEGORY_TYPE, ENQUIRY_TYPE } from "../../utils/stateTypes";

const DEFAULT_CONTACT_FIELDS = {
  name: "",
  email: "",
  cell: "",
  store: "",
  type: ENQUIRY_TYPE.GENERAL,
  message: "",
  newsletter: false,
};

/* State shape same as initial state
*/
const initialState = {
  categories: [],
  categoryDetails: {},
  categoryProducts: [],
  page: 0,
  banners: [],
  product: null,

  contactFields: {
    ...DEFAULT_CONTACT_FIELDS,
  },

  searchResults: [],

  showConsent: false,
  
  pressImages: [],
};

const receiveCategories = (state, action) => (
  updateObject(state, {
    categories: action.categories,
  })
);

const receiveCategoryDetails = (state, action) => (
  updateObject(state, {
    categoryDetails: action.categoryDetails,
  })
);

const receiveCategoryProducts = (state, action) => (
  updateObject(state, {
    categoryProducts: action.categoryProducts,
    page: action.page,
  })
);

const receiveBanners = (state, action) => (
  updateObject(state, {
    banners: action.banners,
  })
);

const receiveProduct = (state, action) => (
  updateObject(state, {
    product: action.product,
  })
);

const updateContactField = (state, action) => (
  updateObject(state, {
    contactFields: {
      ...state.contactFields,
      [action.propToEdit]: action.newValue,
    },
  })
);

const resetContactFields = (state) => (
  updateObject(state, {
    contactFields: {
      ...DEFAULT_CONTACT_FIELDS,
    },
  })
);

const receiveSearchResults = (state, action) => (
  updateObject(state, {
    searchResults: action.searchResults,
  })
);

const updateShowConsent = (state, action) => (
  updateObject(state, {
    showConsent: action.showConsent,
  })
);

const receivePress = (state, action) => (
  updateObject(state, {
    pressImages: action.pressImages,
  })
);

const reducer = createReducer(initialState, {
  [actionType.RECEIVE_CATEGORIES]: receiveCategories,
  [actionType.RECEIVE_CATEGORY_DETAILS]: receiveCategoryDetails,
  [actionType.RECEIVE_CATEGORY_PRODUCTS]: receiveCategoryProducts,
  [actionType.RECEIVE_BANNERS]: receiveBanners,
  [actionType.RECEIVE_PRODUCT]: receiveProduct,

  [actionType.UPDATE_CONTACT_FIELD]: updateContactField,
  [actionType.RESET_CONTACT_FIELDS]: resetContactFields,

  [actionType.REFCEIVE_SEARCH_RESULTS]: receiveSearchResults,

  [actionType.SET_SHOW_CONSENT]: updateShowConsent,

  [actionType.RECEIVE_PRESS]: receivePress,
});

export default reducer;

// SELECTORS
export const getCategories = (state) => (
  state.categories
);

export const getCategoriesTimepieces = (state) => (
  state.categories.filter((category) => (
    category.type === CATEGORY_TYPE.TIMEPIECES
  ))
);

export const getCategoriesJewellery = (state) => (
  state.categories.filter((category) => (
    category.type === CATEGORY_TYPE.JEWELLERY
  ))
);

export const getCategoryDetails = (state) => (
  state.categoryDetails
);

export const getCategoryProducts = (state) => (
  state.categoryProducts
);

export const getPage = (state) => (
  state.page
);

export const getBanners = (state) => (
  state.banners
);

export const getProduct = (state) => (
  state.product
);

export const getContactFields = (state) => (
  state.contactFields
);

export const getSearchResults = (state) => (
  state.searchResults
);

export const getShowConsent = (state) => (
  state.showConsent
);

export const getPressImages = (state) => (
  state.pressImages
);